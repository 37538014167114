import { __decorate } from "tslib";
// Watch
import { Component, Vue } from 'vue-property-decorator';
import { parceriaCupomStore, parceriaStore } from '@/store';
import SaveButton from '@/components/SaveButton.vue';
import SearchEntidadeComponent from '@/components/SearchEntidadeComponent.vue';
import _ from 'lodash';
import { ValidationObserver, ValidationProvider, Validator, } from 'vee-validate';
import { newParceriaCupom } from '@/interfaces/parceria_cupom';
Validator.extend('required', {
    getMessage: (field) => `${field} deve ser preenchido!`,
    validate: async (value) => !!value,
});
let Edit = class Edit extends Vue {
    constructor() {
        super(...arguments);
        this.id = null;
        this.entityObject = null;
        this.search = '';
        this.selectedItem = { id: 0 };
        this.cupons = [];
        this.loading = false;
        this.headers = [
            {
                text: 'Nome',
                sortable: true,
                value: 'nome',
                align: 'left',
            },
            {
                text: 'Telefone',
                sortable: true,
                value: 'telefone',
                align: 'left',
            },
            {
                text: 'Idade',
                sortable: true,
                value: 'idade',
                align: 'left',
            },
            {
                text: 'E-mail',
                sortable: true,
                value: 'email',
                align: 'left',
            },
            {
                text: 'Ações',
                value: 'actions',
            },
        ];
    }
    get parcerias() {
        return parceriaStore.parcerias;
    }
    async submit() {
        const isValid = await this.$refs.observer.validate();
        if (!isValid) {
            // @ts-ignore
            this.$refs.saveButton.changeLoadingStatus(false);
            return;
        }
        this.entityObject.parceria_id = this.selectedItem.id;
        const result = await parceriaCupomStore.createParceriaCupom(this.entityObject);
        // @ts-ignore
        await this.$refs.saveButton.changeLoadingStatus(false);
        this.entityObject.id = result;
        const obj = JSON.parse(JSON.stringify(this.entityObject));
        this.cupons.push(obj);
        this.entityObject.nome = '';
        this.entityObject.telefone = '';
        this.entityObject.idade = '';
        this.entityObject.email = '';
        this.entityObject.id = null;
        await this.$refs.observer.reset();
    }
    async deleteItem(id, index) {
        this.$swal({
            title: 'Confirma a exclusão do cupom?',
            text: 'Após exclusão o cupom não pode ser recuperado!',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Sim, eu confirmo!',
        }).then(async (result) => {
            if (result.isConfirmed) {
                this.loading = true;
                // @ts-ignore
                await parceriaCupomStore.deleteParceriaCupom(id);
                // @ts-ignore
                await parceriaCupomStore.getParceriaCupons();
                this.cupons.splice(index, 1);
                this.loading = false;
            }
        });
    }
    created() {
        this.entityObject = newParceriaCupom();
    }
    async mounted() {
        await parceriaStore.getParcerias();
        if (this.$router.currentRoute.params.id) {
            this.id = this.$router.currentRoute.params.id;
            this.entityObject = await parceriaCupomStore.getParceriaCupomByParceriaId(parseInt(this.$router.currentRoute.params.id, 0));
            this.selectedItem = _.find(this.parcerias, (item) => item.id === this.entityObject.parceria_id);
        }
        else {
            this.entityObject = newParceriaCupom();
        }
    }
};
Edit = __decorate([
    Component({
        components: {
            ValidationObserver,
            ValidationProvider,
            SearchEntidadeComponent,
            SaveButton,
        },
    })
], Edit);
export default Edit;
