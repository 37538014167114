var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"ma-1 pa-3 rounded-xl mb-3"},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card-title',{attrs:{"primary-title":""}},[_c('div',{staticClass:"headline primary--text"},[_vm._v("Cupom")])]),_c('v-card-subtitle',[(_vm.id)?_c('div',[_vm._v("Atualizar Registro")]):_c('div',[_vm._v("Criar Registro")])]),_c('v-card-text',[[_c('v-row',[_c('v-col',{attrs:{"sm":"12","xs":"6","md":"5","lg":"4","xl":"12"}},[_c('validation-provider',{attrs:{"debounce":100,"name":"Parceria","vid":"parceria","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"label":"Parceria","items":_vm.parcerias,"item-text":"nome","error-messages":errors,"return-object":""},model:{value:(_vm.selectedItem),callback:function ($$v) {_vm.selectedItem=$$v},expression:"selectedItem"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.mobile ? '12': '3'}},[_c('validation-provider',{attrs:{"debounce":100,"name":"Nome","vid":"nome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Nome","error-messages":errors},model:{value:(_vm.entityObject.nome),callback:function ($$v) {_vm.$set(_vm.entityObject, "nome", $$v)},expression:"entityObject.nome"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.mobile ? '12': '3'}},[_c('validation-provider',{attrs:{"debounce":100,"name":"Telefone","vid":"telefone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('(##) #####-####'),expression:"'(##) #####-####'"}],attrs:{"label":"Telefone","type":"text","error-messages":errors},model:{value:(_vm.entityObject.telefone),callback:function ($$v) {_vm.$set(_vm.entityObject, "telefone", $$v)},expression:"entityObject.telefone"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.mobile ? '12': '1'}},[_c('validation-provider',{attrs:{"debounce":100,"name":"Idade","vid":"idade","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Idade","type":"number","min":"1","error-messages":errors},model:{value:(_vm.entityObject.idade),callback:function ($$v) {_vm.$set(_vm.entityObject, "idade", $$v)},expression:"entityObject.idade"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.mobile ? '12': '3'}},[_c('v-text-field',{attrs:{"label":"Email","type":"email"},model:{value:(_vm.entityObject.email),callback:function ($$v) {_vm.$set(_vm.entityObject, "email", $$v)},expression:"entityObject.email"}})],1),_c('v-col',[_c('save-button',{ref:"saveButton",attrs:{"prop-show-voltar":false},on:{"submit":function($event){return _vm.submit()}}})],1)],1),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Buscar","single-line":"","append-icon":"mdi-magnify","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{staticClass:"ma-1",attrs:{"multi-sort":"","headers":_vm.headers,"items":_vm.cupons,"search":_vm.search,"loading-text":"Carregando...","loading":_vm.loading,"fixed-header":"","height":"50vh","footer-props":{
                  itemsPerPageOptions:[-1]
                },"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){_vm.deleteItem(item.id, _vm.cupons.indexOf(item.id))}}},[_vm._v("mdi-delete")])]}}],null,true)})],1)],1)]],2),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.$router.back()}}},[_vm._v("Voltar")])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }